:host {
  display: flex;
}

$myt-chip-colors: (
  primary: (
    container-color: $primary-100,
    label-text-color: $primary-800,
    border-color: $primary-200,
    icon-color: $primary-500,
    hover-container-color: $primary-200,
  ),
  accent: (
    container-color: $accent-100,
    label-text-color: $accent-800,
    border-color: $accent-200,
    icon-color: $accent-500,
    hover-container-color: $accent-200,
  ),
  info: (
    container-color: $info-100,
    label-text-color: $info-800,
    border-color: $info-200,
    icon-color: $info-500,
    hover-container-color: $info-200,
  ),
  warning: (
    container-color: $warn-100,
    label-text-color: $warn-800,
    border-color: $warn-200,
    icon-color: $warn-500,
    hover-container-color: $warn-200,
  ),
  error: (
    container-color: $error-100,
    label-text-color: $error-800,
    border-color: $error-200,
    icon-color: $error-500,
    hover-container-color: $error-200,
  ),
  neutral: (
    container-color: $neutral-100,
    label-text-color: $neutral-800,
    border-color: $neutral-200,
    icon-color: $neutral-500,
    hover-container-color: $neutral-200,
  ),
  badge: (
    container-color: $primary-950,
    label-text-color: $white,
    border-color: $primary-950,
    icon-color: $white,
    hover-container-color: $primary-800,
  ),
  default: (
    container-color: $white,
    label-text-color: $neutral-700,
    border-color: $neutral-200,
    icon-color: $neutral-800,
    hover-container-color: $neutral-50,
  ),
  selected: (
    container-color: $accent-700,
    label-text-color: $white,
    border-color: $accent-700,
    icon-color: $white,
    hover-container-color: $accent-800,
  ),
);

.myt-chip.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #{$text-subdued};
  --mdc-chip-elevated-disabled-container-color: #{$bg-disabled-input};
  border: 1px solid;
  border-radius: $unit * 6;
  opacity: 1;
  position: relative;

  &.myt-chip--sm {
    --mdc-chip-container-height: 20px;

    .mdc-evolution-chip__action--primary {
      padding-left: 8px;
      padding-right: 8px;
    }

    .mdc-evolution-chip__text-label {
      font-weight: 500;
      font-size: 12px;
    }
  }

  &.myt-chip--md {
    --mdc-chip-container-height: 24px;
  }

  .mdc-evolution-chip__cell--primary,
  .mdc-evolution-chip__action--primary,
  .mat-mdc-chip-action-label {
    overflow: hidden;
  }

  .mdc-evolution-chip-set__chips {
    max-width: 100%;
  }

  &.mat-mdc-chip-with-avatar {
    --mdc-chip-container-height: 40px;
    --mdc-chip-with-avatar-avatar-size: 32px;
    --mdc-chip-with-icon-icon-size: 32px;
  }

  .myt-chip__remove-button {
    --mdc-chip-with-trailing-icon-trailing-icon-color: #{$neutral-500};
    opacity: 1;
    padding-right: $unit * 1.5;
    padding-left: $unit;

    &:hover {
      --mdc-chip-with-trailing-icon-trailing-icon-color: #{$text-color};
    }
  }

  .myt-chip__text {
    white-space: nowrap;
    user-select: none;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: fit-content;
  }

  &.mat-mdc-chip:hover .mat-mdc-chip-focus-overlay {
    opacity: 0;
  }

  .myt-chip__icon {
    padding-left: calc($unit / 2);

    &-left {
      margin-right: $unit;
      margin-left: -($unit);
    }

    &-right {
      margin-left: $unit;
      margin-right: -($unit);
    }
  }

  .mdc-evolution-chip__text-label {
    font-weight: 500;
    font-size: 15px;
  }

  .mat-mdc-chip-action-label {
    display: flex;
    align-items: center;
  }

  @each $name, $properties in $myt-chip-colors {
    &.myt-chip--#{$name} {
      --mdc-chip-elevated-container-color: #{map-get($properties, 'container-color')};
      --mdc-chip-label-text-color: #{map-get($properties, 'label-text-color')};
      border-color: #{map-get($properties, 'border-color')};

      &.myt-chip--interactive {
        .mdc-evolution-chip__action--presentational {
          cursor: pointer;
        }

        &:hover {
          --mdc-chip-elevated-container-color: #{map-get($properties, 'hover-container-color')};
        }
      }

      .myt-chip__icon .myt-icon {
        color: #{map-get($properties, 'icon-color')};
      }
    }
  }

  &.mat-mdc-chip-disabled {
    border-color: #{$bg-disabled-input};

    .myt-chip__icon .myt-icon {
      color: #{$text-subdued};
    }
  }
}
