@use '../../../../../styles/variables.scss';

.myt-radio-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__option {
    margin-bottom: variables.$unit * 2;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .mat-mdc-radio-button {
    --mdc-form-field-label-text-color: #{variables.$primary-950};
  }

  .mat-mdc-radio-button .mdc-radio {
    --mdc-radio-state-layer-size: 30px;
  }

  .mat-mdc-radio-button .mdc-radio:hover .mdc-radio__native-control:not([disabled]):not(:focus) ~ .mdc-radio__background::before {
    background-color: variables.$accent-700;
    opacity: 0.12;
  }

  .mat-mdc-radio-button
    .mdc-radio:hover
    .mdc-radio__native-control:enabled:not(:checked)
    + .mdc-radio__background
    .mdc-radio__outer-circle {
    border-color: variables.$elevation-3;
  }

  .mat-mdc-radio-button.mat-accent {
    --mdc-radio-selected-focus-icon-color: #{variables.$accent-700};
    --mdc-radio-selected-hover-icon-color: #{variables.$accent-700};
    --mdc-radio-selected-icon-color: #{variables.$accent-700};
    --mdc-radio-selected-pressed-icon-color: #{variables.$accent-700};
  }

  .mdc-label {
    padding-left: variables.$unit * 1.75;
  }

  .myt-radio-group__card-layout {
    display: flex;
    align-items: center;
    min-height: 70px;
    width: 100%;
    border: 2px solid variables.$neutral-200;
    border-radius: variables.$unit * 2;
    margin: variables.$unit * 2 0;
    box-sizing: border-box;
    transition: background-color 300ms;

    .mdc-form-field {
      flex-direction: row-reverse;
      justify-content: space-between;
      width: 100%;

      .mdc-label {
        margin-right: initial;
        flex-grow: 1;
      }
    }

    mat-form-field.mat-mdc-form-field {
      flex-direction: row-reverse;
    }

    &-icon {
      background-color: variables.$neutral-100;
      border-radius: variables.$unit * 2;
      padding: variables.$unit * 3;
      margin-right: variables.$unit * 4;
      transition: background-color 300ms;
      height: fit-content;
    }

    &-text {
      margin-block: auto;
    }

    &-title {
      font-weight: 700;
    }

    &-control {
      display: flex;
      flex-direction: row;
      cursor: pointer;
      padding: variables.$unit * 4 variables.$unit * 2;
      align-items: center;
    }

    .myt-radio-group__option {
      width: 100%;
      padding-right: variables.$unit * 2;
    }

    &--active {
      border-color: variables.$accent-700;
      .myt-radio-group__card-layout-icon {
        background-color: variables.$accent-700;
      }
    }
  }
}
