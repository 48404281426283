@use '../../../../styles/variables.scss';

.myt-button {
  background-color: var(--myt-button-background-color);

  &__icon--right {
    margin-left: 3 * variables.$unit;
  }

  &__icon--left {
    margin-right: 3 * variables.$unit;
  }

  &.mdc-button.mat-unthemed {
    --mdc-filled-button-container-color: #{variables.$primary-700};

    &:focus-visible {
      outline: 2px solid #{variables.$accent-400};
      outline-offset: 1px;
    }

    &:hover {
      --mdc-filled-button-container-color: #{variables.$primary-500};
    }

    &[mat-flat-button] {
      &:active {
        --mat-mdc-button-ripple-color: #{variables.$primary-900};
      }
    }
  }

  &.mdc-button.mat-unthemed,
  &.mdc-button.mat-warn {
    --mdc-filled-button-label-text-color: #{variables.$white};
    --mdc-filled-button-disabled-container-color: #{variables.$bg-disabled-input};
    --mdc-filled-button-disabled-label-text-color: #{variables.$text-subdued};
    --mdc-outlined-button-outline-color: #{variables.$elevation-2};
    --mdc-outlined-button-label-text-color: #{variables.$text-color};
    --mdc-text-button-label-text-color: #{variables.$text-color};

    border-radius: variables.$unit * 2;
    padding: variables.$unit * 3 variables.$unit * 4;
    height: 48px;
    font-family: variables.$font-family;
    min-width: max-content;

    &.myt-button--lg {
      height: 48px;
      padding: variables.$unit * 3 variables.$unit * 4;
    }

    &.myt-button--md {
      padding: variables.$unit * 2 variables.$unit * 4;
      height: 40px;
    }

    .mdc-button__label {
      display: flex;
      align-items: center;

      .myt-button__prefix {
        margin-right: variables.$unit * 2;
        margin-left: -(variables.$unit);
      }

      .myt-button__suffix {
        margin-left: variables.$unit * 2;
        margin-right: -(variables.$unit);
      }
    }
  }

  &.mdc-button.mat-neutral {
    --mdc-filled-button-container-color: #{variables.$neutral-50};
    --mdc-filled-button-label-text-color: #{variables.$primary-950};

    &:hover {
      --mdc-filled-button-container-color: #{variables.$neutral-100};
    }

    &:active {
      --mat-mdc-button-ripple-color: #{variables.$neutral-200};
    }

    &[disabled] {
      --mdc-filled-button-disabled-container-color: #{variables.$neutral-100};
      --mdc-filled-button-disabled-label-text-color: #{variables.$neutral-300};
    }
  }

  &.myt-button--stroked-white.mdc-button.mat-unthemed {
    --mdc-outlined-button-label-text-color: #{variables.$white};
  }

  &.myt-button--stroked-error.mdc-button.mat-unthemed {
    --mdc-outlined-button-label-text-color: #{variables.$error-800};
    --mdc-outlined-button-outline-color: #{variables.$error-400};
    --mat-standard-button-toggle-hover-state-layer-opacity: none;
    &:hover {
      background-color: variables.$error-50;
    }
  }

  &.myt-button--flat-error.mdc-button.mat-unthemed {
    --mdc-outlined-button-label-text-color: #{variables.$error-800};
    --mdc-outlined-button-outline-color: #{variables.$error-400};
    --mat-standard-button-toggle-hover-state-layer-opacity: none;
    &:hover {
      background-color: variables.$error-50;
    }
  }

  &.myt-button--stroked-error.mdc-button.mat-unthemed {
    --mdc-outlined-button-label-text-color: #{variables.$error-800};
    --mdc-outlined-button-outline-color: #{variables.$error-400};
    --mat-standard-button-toggle-hover-state-layer-opacity: none;
    &:hover {
      background-color: variables.$error-50;
    }
  }

  &.myt-button--fab-mini.mat-mdc-mini-fab.mat-primary {
    --myt-button-background-color: #{variables.$primary-700};
  }

  &.mat-mdc-icon-button.mat-mdc-button-base {
    padding: variables.$unit * 3;
    height: 48px;

    &.myt-button--lg {
      height: 48px;
      padding: variables.$unit * 3;
    }

    &.myt-button--md {
      padding: variables.$unit * 2;
      height: 36px;
      width: 36px;
    }

    &.myt-button--sm {
      padding: variables.$unit;
      height: 32px;
      width: 32px;
    }

    &.myt-button--xs {
      padding: variables.$unit;
      height: 28px;
      width: 28px;
    }

    &.myt-button--xxs {
      padding: variables.$unit;
      height: 24px;
      width: 24px;
    }
  }

  &.shadow-none {
    --mdc-fab-container-elevation-shadow: none;
    --mdc-fab-hover-container-elevation-shadow: none;
    --mdc-fab-focus-container-elevation-shadow: none;
    box-shadow: none;
    --mdc-fab-small-pressed-container-elevation-shadow: none;
  }

  &.mat-mdc-icon-button .mat-mdc-button-touch-target {
    width: 100%;
    height: 100%;
  }
}

.myt-button-full-width {
  width: 100%;
}

.myt-button-link {
  color: inherit;
  text-decoration: none !important;
  font-weight: 500;
  cursor: pointer;
}

.myt-button-link--disabled {
  cursor: not-allowed;
  color: variables.$neutral-300;
}
