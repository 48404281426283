/// Example template for a spinner icon
///
/// <div class="myt-spinner-icon ">
///  <div class="myt-spinner-icon__orb1"></div>
///  <div class="myt-spinner-icon__orb2"></div>
///  <div class="myt-spinner-icon__orb3"></div>
///  <div class="myt-spinner-icon__center"></div>
/// </div>
/// <div class="myt-spinner-icon myt-spinner-icon--static">
///  <div class="myt-spinner-icon__orb1"></div>
///  <div class="myt-spinner-icon__orb2"></div>
///  <div class="myt-spinner-icon__orb3"></div>
///  <div class="myt-spinner-icon__center"></div>
///</div>
.myt-spinner-icon {
  /* Base size that controls overall scale */
  --base-size: 24px;

  /* Sizes - all relative to base-size */
  --container-size: var(--base-size);
  --orb-size: calc(var(--base-size) * 0.5); // 50% of base
  --center-size: calc(var(--base-size) * 0.6); // 60% of base

  /* Colors */
  --orb1-color: #{$primary-400};
  --orb2-color: #{$accent-400};
  --orb3-color: #{$info-400};
  --center-color: white;

  /* Rotation speeds */
  --speed-slow: 8s;
  --speed-medium: 2s;
  --speed-fast: 1s;

  /* Blur values - relative to base-size */
  --blur-min: calc(var(--base-size) * 0.15); // 15% of base

  /* Blur animation durations */
  --blur-time-1: 2s;
  --blur-time-2: 4s;
  --blur-time-3: 5s;

  /* Calculate orbit radius */
  --orbit-radius: calc((var(--container-size) / 2) - (var(--orb-size) / 2));

  /* Initial positions */
  --pos1: 0deg;
  --pos2: 120deg;
  --pos3: 240deg;

  position: relative;
  width: var(--container-size);
  height: var(--container-size);
  border-radius: 50%;
  margin: auto;
  overflow: hidden;
  background-color: #f0f8ff;

  .myt-spinner-icon__center {
    position: absolute;
    width: var(--center-size);
    height: var(--center-size);
    background: var(--center-color);
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .myt-spinner-icon__orb1,
  .myt-spinner-icon__orb2,
  .myt-spinner-icon__orb3 {
    position: absolute;
    width: var(--orb-size);
    height: var(--orb-size);
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform-origin: center;
    filter: blur(var(--blur-min));
  }

  .myt-spinner-icon__orb1 {
    background: var(--orb1-color);
    --initial-rotation: var(--pos1);
    animation: rotate var(--speed-slow) linear infinite;
  }

  .myt-spinner-icon__orb2 {
    background: var(--orb2-color);
    --initial-rotation: var(--pos2);
    animation: rotate var(--speed-medium) linear infinite;
  }

  .myt-spinner-icon__orb3 {
    background: var(--orb3-color);
    --initial-rotation: var(--pos3);
    animation: rotate var(--speed-fast) linear infinite;
  }

  &--static {
    .myt-spinner-icon__orb1,
    .myt-spinner-icon__orb2,
    .myt-spinner-icon__orb3 {
      animation: none;
      transform: translate(-50%, -50%) rotate(var(--initial-rotation)) translateY(calc(-1 * var(--orbit-radius)));
    }
  }

  @keyframes rotate {
    from {
      transform: translate(-50%, -50%) rotate(var(--initial-rotation)) translateY(calc(-1 * var(--orbit-radius)));
    }

    to {
      transform: translate(-50%, -50%) rotate(calc(360deg + var(--initial-rotation))) translateY(calc(-1 * var(--orbit-radius)));
    }
  }
}
