@use '../../variables' as vars;

.menu__panel {
  --mat-menu-container-shape: #{vars.$unit * 2};

  border: 1px solid vars.$elevation-2;
  background: vars.$white;

  &.mat-mdc-elevation-specific.mat-elevation-z8 {
    box-shadow: 0 4px 6px 0 transparentize(#000, 0.86);
  }

  .mat-mdc-menu-content {
    padding: 0;
  }
}
