@use '../../../../../styles/variables.scss';

.myt-datepicker {
  display: flex;
  flex-direction: column;

  mat-form-field.mat-mdc-form-field.mat-form-field-invalid {
    .mat-mdc-icon-button.mat-mdc-button-base {
      --mdc-icon-button-icon-color: #eb4848;
    }
  }

  mat-form-field.mat-form-field-disabled {
    .mat-mdc-icon-button.mat-mdc-button-base {
      --mdc-icon-button-icon-color: #aab6b3;
      --mdc-icon-button-disabled-icon-color: #aab6b3;
      color: #aab6b3 !important;
    }
  }
  .mat-mdc-icon-button.mat-mdc-button-base {
    --mat-mdc-button-persistent-ripple-color: transparent;
    --mdc-icon-button-icon-color: #012d20;
    padding: 0;
    height: 24px;
    width: 24px;
  }

  .mat-mdc-button-base:not(a):not(.mat-button):not([disabled]):hover {
    box-shadow: none;
  }

  .mat-mdc-button-ripple {
    width: 0;
  }
}

mat-calendar {
  .mat-calendar-body-selected {
    background-color: variables.$primary;
    font-weight: 600;
  }
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: variables.$primary-100;
    color: variables.$primary-950;
  }
}
