@use '../colors/colors-variables.scss';

.myt-slide-toggle {
  &--accent {
    --mdc-switch-selected-track-color: #{colors-variables.$accent-200};
    --mdc-switch-selected-focus-track-color: #{colors-variables.$accent-200};
    --mdc-switch-selected-hover-track-color: #{colors-variables.$accent-200};
    --mdc-switch-selected-pressed-track-color: #{colors-variables.$accent-200};

    --mdc-switch-selected-focus-state-layer-color: #{colors-variables.$accent-200};
    --mdc-switch-selected-handle-color: #{colors-variables.$accent-700};
    --mdc-switch-selected-pressed-color: #{colors-variables.$accent-700};
  }
}
