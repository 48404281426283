@use '../../../../styles/variables.scss';

.myt-dialog {
  background-color: variables.$white;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.14);
  border-radius: 2 * variables.$unit;

  .mat-mdc-dialog-container {
    --mdc-dialog-container-shape: 8px;
  }

  .mdc-dialog__title::before {
    height: 0;
  }

  .mdc-dialog__title {
    padding: 6 * variables.$unit 6 * variables.$unit 0;
  }

  .mat-mdc-dialog-actions {
    padding: 10 * variables.$unit 6 * variables.$unit 6 * variables.$unit;
    display: flex;
    justify-content: flex-end;
  }

  .mdc-dialog .mdc-dialog__content {
    display: flex;
    flex-direction: column;
    max-height: initial;
    padding: 3 * variables.$unit 6 * variables.$unit 0;
    color: variables.$text-color;
  }

  .mat-mdc-dialog-container .mdc-dialog__title {
    color: variables.$text-color;
    font-size: variables.$unit * 5;
    line-height: 1.2;
    margin: 0;
  }

  &__close-icon {
    position: absolute;
    top: 2 * variables.$unit;
    right: 2 * variables.$unit;
    cursor: pointer;
  }

  &__confirm-button {
    margin-left: 3 * variables.$unit;
  }

  &__content {
    overflow: auto;
    margin-top: variables.$unit;
  }
}
