@import 'node_modules/driver.js/dist/driver.css';

.myt-driver-popover {
  padding: $unit * 4;

  .driver-popover-title,
  .driver-popover-description {
    font-family: $font-family;
    line-height: 18px;
    font-size: 14px;
    color: $text-color;
  }

  .driver-popover-title {
    font-weight: 600;
  }

  .driver-popover-description {
    font-weight: 400;
  }
}
