.myt-checkbox {
  --mdc-checkbox-state-layer-size: 30px;
  --mdc-checkbox-selected-focus-icon-color: #{$accent-700};
  --mdc-checkbox-selected-hover-icon-color: #{$accent-700};
  --mdc-checkbox-selected-icon-color: #{$accent-700};
  --mdc-checkbox-selected-pressed-icon-color: #{$accent-700};
  --mdc-checkbox-selected-focus-state-layer-color: #{$accent-700};
  --mdc-checkbox-selected-hover-state-layer-color: #{$accent-700};
  --mdc-checkbox-selected-pressed-state-layer-color: #{$accent-700};
  --mdc-checkbox-unselected-focus-icon-color: #{$neutral-400};
  --mdc-checkbox-unselected-hover-icon-color: #{$neutral-400};
  --mdc-form-field-label-text-color: inherit;

  .mdc-checkbox__background {
    border-radius: $unit;
  }

  .mdc-checkbox__ripple {
    border-radius: $unit * 2;
    opacity: 0.12 !important;
  }

  .mdc-checkbox:hover .mdc-checkbox__ripple {
    background-color: $accent-700 !important;
  }

  .mdc-checkbox:active .mdc-checkbox__native-control ~ .mdc-checkbox__ripple,
  .mdc-checkbox .mdc-checkbox__native-control:focus ~ .mdc-checkbox__ripple {
    background-color: $accent-700;
  }

  .mdc-checkbox__checkmark {
    transform: scale(0.8);
  }

  .mdc-label {
    padding-left: $unit * 1.5;
    margin-block: auto;
  }

  .mdc-form-field {
    align-items: flex-start;
  }

  a {
    color: inherit;
  }
}
