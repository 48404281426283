$myt-badge-types: (
  primary: (
    background-color: $primary-700,
    background-color-hover: $primary-500,
  ),
  accent: (
    background-color: $accent-700,
    background-color-hover: $accent-500,
  ),
  info: (
    background-color: $info-700,
    background-color-hover: $info-500,
  ),
  warning: (
    background-color: $warn-700,
    background-color-hover: $warn-500,
  ),
  neutral: (
    background-color: $neutral-700,
    background-color-hover: $neutral-500,
  ),
);

.myt-badge {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  color: $white;
  border-radius: 24px;
  width: fit-content;

  &.myt-badge--medium {
    font-size: 15px;
    line-height: 20px;
    padding: 2px 8px;
  }

  &.myt-badge--small {
    font-size: 12px;
    line-height: 16px;
    padding: 2px 8px;
  }

  @each $name, $properties in $myt-badge-types {
    &.myt-badge--#{$name} {
      background-color: #{map-get($properties, 'background-color')};

      &.myt-badge--interactive {
        cursor: pointer;
        &:hover {
          background-color: #{map-get($properties, 'background-color-hover')};
        }
      }

      &.myt-badge--disabled {
        background-color: $neutral-100;
      }
    }
  }
}
