$white: #fdfdfd;
$black: #000000;
$secondary-color: #acf06a;

$bg-disabled: #f2f3ef;
$bg-neutral: #f5f6f6;
$bg-disabled-input: #e5e8e7;

$border-color: #dee1e0;
$border-color-hover: #4a5452;

$elevation-1: #cdd4d2;
$elevation-2: #aab6b3;
$elevation-3: #80908b;

$text-neutral: #414947;
$text-subdued: #aab6b3;
$text-color: #012d20;

$primary-50: #f1fcf6;
$primary-100: #d4f6e2;
$primary-200: #a4ebc1;
$primary-300: #73d9a0;
$primary-400: #4bcb87;
$primary-500: #0bba6e;
$primary-600: #009b60;
$primary-700: #007c50;
$primary-800: #036240;
$primary-900: #045037;
$primary-950: #012d20;

$accent-50: #f0f8ff;
$accent-100: #e2f1ff;
$accent-200: #c2e2ff;
$accent-300: #8ec7fb;
$accent-400: #61b3ff;
$accent-500: #2d91ee;
$accent-600: #0771d2;
$accent-700: #095fad;
$accent-800: #054c8f;
$accent-900: #053e72;
$accent-950: #02274a;

$warn-50: #fef9ec;
$warn-100: #fcedc9;
$warn-200: #f8d98f;
$warn-300: #f5c054;
$warn-400: #f2a82d;
$warn-500: #e78413;
$warn-600: #d1630e;
$warn-700: #ad4510;
$warn-800: #8d3513;
$warn-900: #742d13;
$warn-950: #421506;

$error-50: #fef2f2;
$error-100: #fde3e3;
$error-200: #fdcbcb;
$error-300: #faa7a7;
$error-400: #f57474;
$error-500: #eb4848;
$error-600: #cf2626;
$error-700: #b52020;
$error-800: #961e1e;
$error-900: #7d1f1f;
$error-950: #440b0b;

$info-50: #f9f7fc;
$info-100: #f3edfa;
$info-200: #e7dbf3;
$info-300: #d5bee9;
$info-400: #be98da;
$info-500: #9f6ec7;
$info-600: #844faa;
$info-700: #764497;
$info-800: #5b3573;
$info-900: #4e305f;
$info-950: #2e163c;

$neutral-50: #f5f6f6;
$neutral-100: #ededed;
$neutral-150: #e1e1e1;
$neutral-200: #d1d1d1;
$neutral-300: #b9bab9;
$neutral-400: #919392;
$neutral-500: #7c7e7c;
$neutral-600: #666967;
$neutral-700: #4c504e;
$neutral-800: #3e423f;
$neutral-900: #313431;
$neutral-950: #242825;

$primary: $primary-700;
$accent: $accent-700;
$warning: $warn-700;
$error: $error-700;
$info: $info-700;
$neutral: $neutral-700;
