@use '@angular/material' as mat;
@import 'reset';
@import 'variables';
@import 'typography/typography';
@import 'colors/palette';

@tailwind utilities;

$myt-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $myt-primary,
      accent: $myt-accent,
      warn: $myt-error,
    ),
    density: 0,
    typography: $myT-desktop-typography,
  )
);

$myt-mobile-theme: mat.m2-define-light-theme(
  (
    typography: $myT-mobile-typography,
  )
);

.cdk-overlay-backdrop.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.5);
}

@import '../components/src/lib/breadcrumb/breadcrumb.component.scss';
@import '../components/src/lib/button/button.component';
@import '../components/src/lib/dialog/dialog.component';
@import '../components/src/lib/form-controls/inputs';
@import '../components/src/lib/form-controls/datepicker/datepicker.component.scss';
@import '../components/src/lib/form-controls/label/label.component.scss';
@import '../components/src/lib/form-controls/select/select.component';
@import '../components/src/lib/form-controls/radio-button/radio-button.component';
@import '../components/src/lib/form-controls/autocomplete/autocomplete.component.scss';
@import '../components/src/lib/icon/icon.component';
@import '../components/src/lib/table/table.component';
@import '../components/src/lib/tabs/tabs.component';
@import '../components/src/lib/tooltip/tooltip.directive';

// Global level ui component styles
@import './components/link.scss';
@import './components/menu/menu';
@import './components/menu/settings-sidebar';
@import './components/snackbar';
@import './components/expansion-panel';
@import './components/card';
@import './components/slide-toggle.scss';
@import './components/chip';
@import './components/chip-input';
@import './components/filter.scss';
@import './components/side-panel.scss';
@import './components/markdown.scss';
@import './components/multi-color-spinner.scss';
@import './components/driver.scss';
@import './components/badge.scss';
@import './components/checkbox.scss';

@include mat.core();

// Emit theme-dependent styles for common features used across multiple components.
@include mat.all-component-themes($myt-theme);
@include mat.typography-hierarchy($myt-theme);

// Hotfix for sticky sidebar navigation
.platform__content:has(.platform-content-overflow-x-initial) {
  overflow-x: initial !important;
}

@media only screen and (max-width: $mobile-screen-width) {
  @include mat.all-component-typographies($myt-mobile-theme);
}
