.myt-markdown {
  * {
    line-height: 20px;
    color: $neutral-700;
    letter-spacing: 0.025em;
  }

  p,
  li {
    font-size: 15px;
  }

  p {
    display: block;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  strong {
    font-weight: 600;
  }

  ol ul {
    list-style-type: circle;
    margin-block-start: 0px;
    margin-block-end: 0px;
  }

  ul,
  ol {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    unicode-bidi: isolate;
  }
}
