@use '../../../../styles/variables.scss';

.myt-breadcrumb {
  font-size: 15px;

  &__previous {
    cursor: pointer;
    text-decoration: none;
    font-weight: 500;
    color: variables.$neutral-600;

    &:hover:not(.myt-breadcrumb__previous--no-link) {
      text-decoration: underline solid variables.$text-color 2px;
      color: variables.$text-color;
    }
    &--no-link {
      cursor: default;
    }
  }

  &__current {
    font-weight: 600;
    color: variables.$text-color;
  }

  &__home-icon {
    cursor: pointer;
    color: variables.$neutral-600;
    &:hover {
      color: variables.$text-color;
    }
  }

  &__next-icon {
    margin: 0 variables.$unit;
  }
}
