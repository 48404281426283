@use '../../../../../styles/variables.scss';

.cdk-overlay-pane {
  div.mat-mdc-autocomplete-panel.myt-autocomplete {
    margin-top: variables.$unit * 2;
    border-radius: variables.$unit * 2 !important;
    max-height: 200px;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.14);
    padding: 0;
    border: 1px solid variables.$elevation-2;
    --mat-minimal-pseudo-checkbox-selected-checkmark-color: #{variables.$accent-800};

    .mat-mdc-option {
      min-height: 20px;
      padding: variables.$unit * 3 variables.$unit * 4;
      border-bottom: none;

      &.autocomplete__option--last-pinned {
        border-bottom: 1px solid variables.$elevation-1;
      }
    }

    .mdc-list-item__primary-text {
      line-height: 20px;
      display: flex;
      width: 100%;
      align-items: center;
      color: variables.$text-color;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .mat-mdc-option.mdc-list-item--disabled {
      background-color: variables.$bg-disabled-input;

      .mdc-list-item__primary-text {
        color: variables.$text-subdued;
        opacity: 1;
      }
    }

    &.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
      color: variables.$accent-800;
      font-weight: 500;
    }
  }
}

.loading {
  opacity: 0.4;
}
