@use '../../../../../styles/variables.scss';

.myt-label {
  display: flex;
  align-items: center;
  font-weight: 600;
  color: variables.$text-color;
  font-size: 15px;
  line-height: 20px;

  &--error {
    color: variables.$error-800;
  }

  &__required-sign {
    color: variables.$error-800;
  }
}
