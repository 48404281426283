@use 'sass:map';
@use '@angular/material' as mat;

@import './font-loader';
@import './set-typography';

$desktop-font-sizes: (
  fonts: (
    'headline-1': $unit * 12,
    'headline-2': $unit * 10,
    'headline-3': $unit * 8,
    'headline-4': $unit * 7,
    'headline-5': $unit * 6,
    'headline-6': $unit * 5,
    'subtitle-1': $unit * 4.5,
    'subtitle-2': $unit * 4,
    'body-1': $unit * 3.75,
    'body-2': $unit * 3.5,
    'caption': $unit * 3,
    'button': $unit * 3.75,
  ),
  line-heights: (
    'headline-1': 1.2,
    'headline-2': 1.2,
    'headline-3': 1.2,
    'headline-4': 1.2,
    'headline-5': 1.2,
    'headline-6': 1.2,
    'subtitle-1': 1.2,
    'subtitle-2': 1.2,
    'body-1': $unit * 5,
    'body-2': $unit * 4.5,
    'caption': $unit * 4,
    'button': $unit * 6,
  ),
);

$mobile-font-sizes: (
  fonts: (
    'headline-1': $unit * 9.5,
    'headline-2': $unit * 8,
    'headline-3': $unit * 7,
    'headline-4': $unit * 6,
    'headline-5': $unit * 5.5,
    'headline-6': $unit * 5,
    'subtitle-1': $unit * 5,
    'subtitle-2': $unit * 4,
    'body-1': $unit * 3.75,
    'body-2': $unit * 3.5,
    'caption': $unit * 3,
    'button': $unit * 3.75,
  ),
  line-heights: (
    'headline-1': 1.2,
    'headline-2': 1.2,
    'headline-3': 1.2,
    'headline-4': 1.2,
    'headline-5': 1.2,
    'headline-6': 1.2,
    'subtitle-1': 1.2,
    'subtitle-2': 1.2,
    'body-1': $unit * 5,
    'body-2': $unit * 5,
    'caption': $unit * 4,
    'button': $unit * 6,
  ),
);

// Desktop typography
$headline-1-level: mat.m2-define-typography-level(
  $font-family: $font-family,
  $font-weight: 600,
  $font-size: map.get($desktop-font-sizes, 'fonts', 'headline-1'),
  $line-height: map.get($desktop-font-sizes, 'line-heights', 'headline-1'),
  $letter-spacing: -1px,
);

$headline-2-level: mat.m2-define-typography-level(
  $font-family: $font-family,
  $font-weight: 600,
  $font-size: map.get($desktop-font-sizes, 'fonts', 'headline-2'),
  $line-height: map.get($desktop-font-sizes, 'line-heights', 'headline-2'),
  $letter-spacing: -1px,
);

$headline-3-level: mat.m2-define-typography-level(
  $font-family: $font-family,
  $font-weight: 600,
  $font-size: map.get($desktop-font-sizes, 'fonts', 'headline-3'),
  $line-height: map.get($desktop-font-sizes, 'line-heights', 'headline-3'),
  $letter-spacing: -1px,
);

$headline-4-level: mat.m2-define-typography-level(
  $font-family: $font-family,
  $font-weight: 600,
  $font-size: map.get($desktop-font-sizes, 'fonts', 'headline-4'),
  $line-height: map.get($desktop-font-sizes, 'line-heights', 'headline-4'),
  $letter-spacing: -0.5px,
);

$headline-5-level: mat.m2-define-typography-level(
  $font-family: $font-family,
  $font-weight: 600,
  $font-size: map.get($desktop-font-sizes, 'fonts', 'headline-5'),
  $line-height: map.get($desktop-font-sizes, 'line-heights', 'headline-5'),
  $letter-spacing: -0.5px,
);

$headline-6-level: mat.m2-define-typography-level(
  $font-family: $font-family,
  $font-weight: 600,
  $font-size: map.get($desktop-font-sizes, 'fonts', 'headline-6'),
  $line-height: map.get($desktop-font-sizes, 'line-heights', 'headline-6'),
  $letter-spacing: -0.25px,
);

$subtitle-1-level: mat.m2-define-typography-level(
  $font-family: $font-family,
  $font-weight: 600,
  $font-size: map.get($desktop-font-sizes, 'fonts', 'subtitle-1'),
  $line-height: map.get($desktop-font-sizes, 'line-heights', 'subtitle-1'),
  $letter-spacing: -0.25px,
);

$subtitle-2-level: mat.m2-define-typography-level(
  $font-family: $font-family,
  $font-weight: 600,
  $font-size: map.get($desktop-font-sizes, 'fonts', 'subtitle-2'),
  $line-height: map.get($desktop-font-sizes, 'line-heights', 'subtitle-2'),
  $letter-spacing: -0.25px,
);

$body-1-level: mat.m2-define-typography-level(
  $font-family: $font-family,
  $font-weight: 400,
  $font-size: map.get($desktop-font-sizes, 'fonts', 'body-1'),
  $line-height: map.get($desktop-font-sizes, 'line-heights', 'body-1'),
  $letter-spacing: 0,
);

$body-2-level: mat.m2-define-typography-level(
  $font-family: $font-family,
  $font-weight: 400,
  $font-size: map.get($desktop-font-sizes, 'fonts', 'body-2'),
  $line-height: map.get($desktop-font-sizes, 'line-heights', 'body-2'),
  $letter-spacing: 0,
);

$caption-level: mat.m2-define-typography-level(
  $font-family: $font-family,
  $font-weight: 400,
  $font-size: map.get($desktop-font-sizes, 'fonts', 'caption'),
  $line-height: map.get($desktop-font-sizes, 'line-heights', 'caption'),
  $letter-spacing: 0,
);

$button-level: mat.m2-define-typography-level(
  $font-family: $font-family,
  $font-weight: 600,
  $font-size: map.get($desktop-font-sizes, 'fonts', 'button'),
  $line-height: map.get($desktop-font-sizes, 'line-heights', 'button'),
  $letter-spacing: 0,
);

// Mobile typography
$headline-1-mobile-level: mat.m2-define-typography-level(
  $font-family: $font-family,
  $font-weight: 600,
  $font-size: map.get($mobile-font-sizes, 'fonts', 'headline-1'),
  $line-height: map.get($mobile-font-sizes, 'line-heights', 'headline-1'),
  $letter-spacing: -1px,
);

$headline-2-mobile-level: mat.m2-define-typography-level(
  $font-family: $font-family,
  $font-weight: 600,
  $font-size: map.get($mobile-font-sizes, 'fonts', 'headline-2'),
  $line-height: map.get($mobile-font-sizes, 'line-heights', 'headline-2'),
  $letter-spacing: -1px,
);

$headline-3-mobile-level: mat.m2-define-typography-level(
  $font-family: $font-family,
  $font-weight: 600,
  $font-size: map.get($mobile-font-sizes, 'fonts', 'headline-3'),
  $line-height: map.get($mobile-font-sizes, 'line-heights', 'headline-3'),
  $letter-spacing: -1px,
);

$headline-4-mobile-level: mat.m2-define-typography-level(
  $font-family: $font-family,
  $font-weight: 600,
  $font-size: map.get($mobile-font-sizes, 'fonts', 'headline-4'),
  $line-height: map.get($mobile-font-sizes, 'line-heights', 'headline-4'),
  $letter-spacing: -0.5px,
);

$headline-5-mobile-level: mat.m2-define-typography-level(
  $font-family: $font-family,
  $font-weight: 600,
  $font-size: map.get($mobile-font-sizes, 'fonts', 'headline-5'),
  $line-height: map.get($mobile-font-sizes, 'line-heights', 'headline-5'),
  $letter-spacing: -0.5px,
);

$headline-6-mobile-level: mat.m2-define-typography-level(
  $font-family: $font-family,
  $font-weight: 600,
  $font-size: map.get($mobile-font-sizes, 'fonts', 'headline-6'),
  $line-height: map.get($mobile-font-sizes, 'line-heights', 'headline-6'),
  $letter-spacing: -0.25px,
);

$subtitle-1-mobile-level: mat.m2-define-typography-level(
  $font-family: $font-family,
  $font-weight: 600,
  $font-size: map.get($mobile-font-sizes, 'fonts', 'subtitle-1'),
  $line-height: map.get($mobile-font-sizes, 'line-heights', 'subtitle-1'),
  $letter-spacing: -0.25px,
);

$subtitle-2-mobile-level: mat.m2-define-typography-level(
  $font-family: $font-family,
  $font-weight: 600,
  $font-size: map.get($mobile-font-sizes, 'fonts', 'subtitle-2'),
  $line-height: map.get($mobile-font-sizes, 'line-heights', 'subtitle-2'),
  $letter-spacing: -0.25px,
);

$body-1-mobile-level: mat.m2-define-typography-level(
  $font-family: $font-family,
  $font-weight: 400,
  $font-size: map.get($mobile-font-sizes, 'fonts', 'body-1'),
  $line-height: map.get($mobile-font-sizes, 'line-heights', 'body-1'),
  $letter-spacing: 0,
);

$body-2-mobile-level: mat.m2-define-typography-level(
  $font-family: $font-family,
  $font-weight: 400,
  $font-size: map.get($mobile-font-sizes, 'fonts', 'body-2'),
  $line-height: map.get($mobile-font-sizes, 'line-heights', 'body-2'),
  $letter-spacing: 0,
);

$caption-mobile-level: mat.m2-define-typography-level(
  $font-family: $font-family,
  $font-weight: 400,
  $font-size: map.get($mobile-font-sizes, 'fonts', 'caption'),
  $line-height: map.get($mobile-font-sizes, 'line-heights', 'caption'),
  $letter-spacing: 0,
);

$button-mobile-level: mat.m2-define-typography-level(
  $font-family: $font-family,
  $font-weight: 600,
  $font-size: map.get($mobile-font-sizes, 'fonts', 'button'),
  $line-height: map.get($mobile-font-sizes, 'line-heights', 'button'),
  $letter-spacing: 0,
);

$myT-desktop-typography: mat.m2-define-typography-config(
  $headline-1: $headline-1-level,
  $headline-2: $headline-2-level,
  $headline-3: $headline-3-level,
  $headline-4: $headline-4-level,
  $headline-5: $headline-5-level,
  $headline-6: $headline-6-level,
  $body-1: $body-1-level,
  $body-2: $body-2-level,
  $caption: $caption-level,
  $button: $button-level,
  $subtitle-1: $subtitle-1-level,
  $subtitle-2: $subtitle-2-level,
);

$myT-mobile-typography: mat.m2-define-typography-config(
  $headline-1: $headline-1-mobile-level,
  $headline-2: $headline-2-mobile-level,
  $headline-3: $headline-3-mobile-level,
  $headline-4: $headline-4-mobile-level,
  $headline-5: $headline-5-mobile-level,
  $headline-6: $headline-6-mobile-level,
  $body-1: $body-1-mobile-level,
  $body-2: $body-2-mobile-level,
  $caption: $caption-mobile-level,
  $button: $button-mobile-level,
  $subtitle-1: $subtitle-1-mobile-level,
  $subtitle-2: $subtitle-2-mobile-level,
);

html,
body {
  font-family: $font-family;
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote {
  color: $text-color;
}
