$navbar-height: 62px;

.myt-side-panel {
  &__content {
    background-color: white;
    position: fixed;
    overflow-y: scroll;
    min-width: 500px;
    height: calc(100vh - (#{$navbar-height}));
    top: $navbar-height;
    transition: transform 400ms;
    z-index: 1000;

    &--left {
      transform: translateX(-500px);
      left: 0;
    }

    &--right {
      transform: translateX(500px);
      right: 0;
    }

    &--opened {
      transform: translateX(0);
    }
  }
}
