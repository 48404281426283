@use '../../../../styles/variables.scss';

.myt-tabs {
  --mat-tab-header-divider-height: 1px;
  --mat-tab-header-divider-color: #{variables.$neutral-200};
  --mdc-tab-indicator-active-indicator-height: 0;
  --mdc-secondary-navigation-tab-container-height: 52px;
  --mat-tab-header-inactive-label-text-color: #{variables.$border-color-hover};
  --mat-tab-header-active-label-text-color: #{variables.$primary-800};
  --mat-tab-header-active-ripple-color: transperent;
  --mat-tab-header-inactive-ripple-color: transperent;
  --mat-tab-header-inactive-focus-label-text-color: #{variables.$text-color};
  --mat-tab-header-inactive-hover-label-text-color: #{variables.$text-color};
  --mat-tab-header-active-focus-label-text-color: #{variables.$primary-700};
  --mat-tab-header-active-hover-label-text-color: #{variables.$primary-700};

  .mat-mdc-tab.mdc-tab {
    padding: 0 variables.$unit * 4;

    myt-icon {
      margin-right: variables.$unit;
      margin-left: -(variables.$unit);
      color: variables.$text-color;
    }

    &:hover {
      border-bottom: 3px solid variables.$elevation-2;
    }

    &.mdc-tab-indicator--active {
      border-bottom: 3px solid variables.$primary-700;
    }
  }

  .mat-mdc-tab-body-wrapper {
    padding: (3 * variables.$unit) 0;
  }

  &--no-content {
    .mat-mdc-tab-body-wrapper {
      padding: 0;
    }
  }
}
