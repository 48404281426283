.myt-chip-input {
  mat-form-field.mat-mdc-form-field {
    .mat-mdc-form-field-flex {
      padding-block: $unit * 1;
    }

    input {
      padding-block: $unit * 2;
    }

    .myt-chip-input__input--hidden {
      display: none;
    }

    .mdc-evolution-chip-set .mdc-evolution-chip {
      margin-block: $unit * 1.5;
    }

    .mdc-evolution-chip__action.mdc-evolution-chip__cell {
      cursor: default;
    }
  }
}
