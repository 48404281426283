.mdc-snackbar {
  justify-content: flex-start;
  width: 600px;
  min-height: 44px;
  border: solid;
  border-radius: 10px;
  --mdc-snackbar-container-shape: 9px;

  .mdc-snackbar__surface {
    width: 100%;
    padding-right: 0;
  }

  .mdc-snackbar__label {
    padding: $unit * 3 0;

    a {
      @apply cursor-pointer underline;
    }
  }

  &.snackbar-success {
    --mdc-snackbar-container-color: #{$primary-100};
    --mdc-snackbar-supporting-text-color: #{$primary-800};
    border-color: $primary-400;
  }

  &.snackbar-error {
    --mdc-snackbar-container-color: #{$error-100};
    --mdc-snackbar-supporting-text-color: #{$error-900};
    border-color: $error-400;
    a {
      color: $error-900;
    }
  }

  &.snackbar-warning {
    --mdc-snackbar-container-color: #{$warn-100};
    --mdc-snackbar-supporting-text-color: #{$warn-800};
    border-color: $warn-400;
  }

  &.snackbar-info {
    --mdc-snackbar-container-color: #{$accent-100};
    --mdc-snackbar-supporting-text-color: #{$accent-800};
    border-color: $accent-400;
  }
}
@media only screen and (min-width: 600px) {
  .mdc-snackbar {
    margin: 0 0 $unit * 15 $unit * 15 !important;
  }
}
