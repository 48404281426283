@import './colors-variables';
/**
Backgrounds
**/

.bg-white {
  background-color: $white !important;
}

.bg-primary {
  background-color: $primary !important;
}

.bg-accent {
  background-color: $accent !important;
}

.bg-error {
  background-color: $error !important;
}

.bg-warning {
  background-color: $warning !important;
}

/**
Text colors
**/

.text-primary {
  color: $primary;
}

.text-accent {
  color: $accent;
}

.text-error {
  color: $error;
}

.text-warning {
  color: $warning;
}

.text-neutral {
  color: $text-subdued;
}

.text-info {
  color: $info;
}

.text-color {
  color: $text-color;
}

.text-light {
  color: $white;
}

.text-black {
  color: $black;
}
