.myt-filter {
  min-height: 20px;

  &-overlay {
    background-color: white !important;
    border-color: #{$neutral-200};
    border-style: solid;
    border-width: 1px;
    border-radius: 6px;
    margin-top: 4px;

    &__input mat-form-field .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
      // Removes space reserved for the hint below the input.
      display: none;
    }

    .mdc-checkbox__ripple {
      // Removes the default hover effect around the checkbox.
      display: none;
    }

    &__options-list {
      overflow: scroll;
      max-height: 288px;

      .mat-mdc-checkbox-touch-target {
        // Makes the entire checkbox div clickable.
        width: 100%;
        padding-left: 12px;
        padding-right: 12px;
        overflow: hidden;
      }
    }
  }
}
