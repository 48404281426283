@mixin expansion-panel-padding($header-padding-block, $header-padding-inline, $body-padding-inline) {
  .mat-expansion-panel-header {
    padding-inline: $header-padding-inline;
    padding-block: $header-padding-block;
    align-items: normal;
  }
  .mat-expansion-panel-body {
    padding-inline: $body-padding-inline;
  }
}

$panel-types: (
  default: (
    background-color: var(--mat-expansion-container-background-color),
    indicator-color: $neutral-800,
    header-text-color: $text-color,
  ),
  primary: (
    background-color: mix($primary-950, $white, 93%),
    indicator-color: $neutral-300,
    header-text-color: $primary-300,
  ),
  neutral: (
    background-color: $neutral-50,
    indicator-color: $neutral-800,
    header-text-color: $text-color,
  ),
  error: (
    background-color: $error-50,
    indicator-color: $neutral-800,
    header-text-color: $error-800,
  ),
);

.myt-expansion-panel.mat-accordion {
  --mat-expansion-header-expanded-state-height: none;
  --mat-expansion-header-collapsed-state-height: none;
  @include expansion-panel-padding($unit * 4, $unit * 4, $unit * 4);

  &.myt-expansion-panel--divider > .mat-expansion-panel > .mat-expansion-panel-content {
    border-top: 1px solid $neutral-200;
  }

  &.myt-expansion-panel--large {
    @include expansion-panel-padding($unit * 5, $unit * 6, $unit * 6);
  }

  &.myt-expansion-panel--no-content-padding > .mat-expansion-panel > .mat-expansion-panel-content > .mat-expansion-panel-body {
    padding: 0;
  }

  &.myt-expansion-panel--highlight {
    background-color: $accent-100;
    border: 2px solid $accent-400;
    border-radius: 0%;
  }

  &.myt-expansion-panel--no-hover .mat-expansion-panel-header {
    &:focus,
    &:hover {
      background: none;
    }
  }

  .mat-expansion-panel-header-title {
    max-width: 100%;
    margin-right: 0;
  }
  .mat-expansion-indicator {
    display: flex;
    align-items: center;
  }

  .mat-expansion-panel {
    box-shadow: none;
    border-radius: 0;
  }

  @each $name, $properties in $panel-types {
    &.myt-expansion-panel--#{$name} {
      --mat-expansion-container-background-color: #{map-get($properties, 'background-color')};
      --mat-expansion-header-indicator-color: #{map-get($properties, 'indicator-color')};
      --mat-expansion-header-text-color: #{map-get($properties, 'header-text-color')};

      // Temporary fix for primary expansion panel
      @if $name == primary {
        --mat-expansion-container-shape: #{$unit * 2};
      }
    }
  }
}
